// all storage keys gathered here to prevent duplicate name
export const CONNECTED_WALLETS = "connectedWallets"

export const TOKEN_SYMBOL_MAP_KEY = "tokenSymbolMap"

export const TOKEN_INFO_MAP = "tokenInfoMap"

export const BRIDGE_TRANSACTIONS = "bridgeTransactions"

export const CLAIM_TRANSACTIONS = "claimTransactions"

export const BRIDGE_TRANSACTIONS_V2 = "bridgeTransactionsV2"

export const CLAIM_TRANSACTIONS_V2 = "claimTransactions2"

export const APP_VERSION = "appVersion"

export const BLOCK_NUMBERS = "blockNumbers"

export const USER_TOKEN_LIST = "userTokenList"

export const STORAGE_AVAILABLE = "__storage_test__"

export const ENVIRONMENT_NAME = "environmentName"

export const SIGNED_TERMS = "signedTerms"

export const WALLET_MARKS = "walletMarks"

export const BRIDGE_BALANCES = "bridgeBalances"

export const BLOG_LANGUAGE = "blogLanguage"

export const DISPLAYED_CANVAS = "displayedCanvas"
